// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACF;;AAKA;EACE,uCAAA;AAFF;;AAKA;EACE;IAAK,uBAAA;EADL;EAEA;IAAO,yBAAA;EACP;AACF;AACA;EACE,qBAbO;EAcP,oBAAA;EACA,wBAAA;EACA,2EACD;AAAD;;AAIA;EACC;IAAK,eAAA;EAAJ;EACD;IAAM,eAAA;EAEL;EADD;IAAM,eAAA;EAIL;EAHD;IAAM,eAAA;EAML;EALD;IAAO,eAAA;EAQN;AACF;AANA;EACC;IAAK,sBA9BG;EAuCP;EARD;IACA,wBAAA;IACA,yBAAA;EAUC;EARD;IACA,sBApCQ;IAqCR,yBAAA;EAUC;AACF","sourcesContent":[".spinner-wrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100%;\r\n}\r\n\r\n$offset: 187;\r\n$duration: 1.4s;\r\n\r\n.spinner {\r\n  animation: rotator $duration linear infinite;\r\n}\r\n\r\n@keyframes rotator {\r\n  0% { transform: rotate(0deg); }\r\n  100% { transform: rotate(270deg); }\r\n}\r\n\r\n.path {\r\n  stroke-dasharray: $offset;\r\n  stroke-dashoffset: 0;\r\n  transform-origin: center;\r\n  animation:\r\n\tdash $duration ease-in-out infinite,\r\n    colors ($duration*4) ease-in-out infinite;\r\n}\r\n\r\n@keyframes colors {\r\n\t0% { stroke: #4285F4; }\r\n\t25% { stroke: #DE3E35; }\r\n\t50% { stroke: #F7C223; }\r\n\t75% { stroke: #1B9A59; }\r\n\t100% { stroke: #4285F4; }\r\n}\r\n\r\n@keyframes dash {\r\n 0% { stroke-dashoffset: $offset; }\r\n 50% {\r\n\tstroke-dashoffset: calc($offset/4);\r\n\ttransform:rotate(135deg);\r\n }\r\n 100% {\r\n\tstroke-dashoffset: $offset;\r\n\ttransform:rotate(450deg);\r\n }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
