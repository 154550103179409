// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AgGridModule } from "ag-grid-angular";

import { MaterialModule } from "./material.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { LoginComponent } from "./user/login.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./home.component";
import { FeedsComponent } from "./feeds/feeds.component";
import { AuthInterceptor } from "./user/auth.interceptor";
import { UseradminComponent } from './useradmin/useradmin.component';
import { UsersignupComponent } from './user/usersignup.component';

@NgModule({
	declarations: [
		AppComponent,
		SpinnerComponent,
		LoginComponent,
		HomeComponent,
		FeedsComponent,
		UseradminComponent,
  		UsersignupComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		MaterialModule,
		HttpClientModule,
		DragDropModule,
		AppRoutingModule,
		AgGridModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
