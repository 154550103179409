import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgForm } from "@angular/forms";
import { AgGridAngular } from "ag-grid-angular";
import { CellValueChangedEvent, ColDef } from "ag-grid-community";

import { DataService } from "../services/data.service";
import { User } from "../model/user";

@Component({
	selector: "app-useradmin",
	templateUrl: "./useradmin.component.html",
	styleUrls: ["./useradmin.component.scss"],
})
export class UseradminComponent implements OnInit {
	@ViewChild("agGrid", { read: AgGridAngular, static: true })
	agGrid!: AgGridAngular;

	public title = "User Admin";
	public showAddUser = false;
	public rowData: User[] = [];

	public columnDefs: ColDef[] = [
		{
			headerName: "User ID",
			field: "id",
			checkboxSelection: true,
			width: 80,
		},
		{
			headerName: "Username",
			field: "username",
			editable: true,
			width: 100,
			minWidth: 100,
			maxWidth: 150,
		},
		{
			headerName: "Email Address",
			field: "emailAddress",
			editable: true,
			width: 250,
			minWidth: 100,
			maxWidth: 400,
		},
		{
			headerName: "Admin?",
			field: "admin",
			editable: true,
			width: 70,
			minWidth: 70,
			maxWidth: 70,
		},
	];

	constructor(
		private _dataSvc: DataService,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.getUsers();
	}

	private getUsers(): void {
		this._dataSvc.getUsers().subscribe((data) => {
			if (data !== null && typeof data !== "number") {
				this.rowData = data;
			} else if (data === 401) {
				this.displayMessage(
					"There was an error retrieving the users. You don't appear to be logged in.",
					"Close"
				);
			} else if (data === 403) {
				this.displayMessage(
					"You are not authorized to see the list of users. Perhaps you are not an Administrator.",
					"Close"
				);
			} else {
				this.displayMessage(
					"An unknown error ocurred. The attempt to retrieve the users failed.",
					"Close"
				);
			}
		});
	}

	private updateUser($event: CellValueChangedEvent): void {
		const eq = $event.oldValue === $event.newValue;
		if (eq) {
			// this.displayMessage("You didn't change the field. The update was skipped.", "");
			this.getUsers();
			return;
		}
		this._dataSvc.updateUser($event.data).subscribe(
			(response) => {
				if (response === 204) {
					this.displayMessage("Successfully Updated User", "Close");
				}
			},
			(error) => {
				this.displayMessage(
					"An error occurred. The update failed.",
					"Close"
				);
			}
		);
	}

	public deleteUser(): void {
		const selectedNodes = this.agGrid.api.getSelectedNodes();
		if (selectedNodes.length === 0) {
			this.displayMessage("Please Select a User to Delete", "Close");
			return;
		}
		const selectedData = selectedNodes.map((node) => node.data);
		this._dataSvc.deleteUser(selectedData[0].id).subscribe(
			(response) => {
				if (response === 204) {
					this.displayMessage("Successfully Deleted User", "Close");
					this.getUsers();
				}
			},
			(error) => {
				this.displayMessage(
					"An error occurred. The operation failed.",
					"Close"
				);
			}
		);
	}

	public addUser(addUserForm: NgForm): void {
		if (addUserForm && addUserForm.valid) {
			const userToAdd: User = {
				id: 0,
				username: addUserForm.form.value.fUsername.trim(),
				password: addUserForm.form.value.fPassword.trim(),
				emailAddress: addUserForm.form.value.fEmail.trim(),
				admin: 0,
				salt: "",
				token: ""
			};
			this._dataSvc.addUser(userToAdd).subscribe(
				(response) => {
					this.displayMessage("Successfully Added User", "Close");
					this.getUsers();
				},
				(error) => {
					this.displayMessage(error.error.message, "Close");
				},
				() => {
					addUserForm.resetForm();
					this.toggleAddUser();
				}
			);
		}
	}

	public toggleAddUser(): void {
		this.showAddUser = !this.showAddUser;
	}

	public onCellValueChanged($event: CellValueChangedEvent): void {
		this.updateUser($event);
	}

	private displayMessage(message: string, action: string): void {
		this._snackBar.open(message, action, { duration: 5000 });
	}
}
