// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from "@angular/router";

import { AuthService } from "../services/auth.service";

@Injectable({
	providedIn: "root"
})
class AuthGuardService {

	constructor(private _authService: AuthService, private _router: Router) {}

	canActivate(
			next: ActivatedRouteSnapshot,
			state: RouterStateSnapshot
		): boolean {
			return this.checkLoggedIn(state.url);
	}

	checkLoggedIn(url: string): boolean {
		// If user is logged in, go.
		if (this._authService.isLoggedIn) {
			return true;
		}
		// If the user is not logged in: store the destination,
		// make them log in, then redirect to the original destination.
		this._authService.redirectUrl = url;
		this._router.navigate(["/login"]);
		return false;
	}
}

export const AuthGuard: CanActivateFn = (
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean => {
		return inject(AuthGuardService).canActivate(next, state);
}
