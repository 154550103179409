// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* User Defined */
h2 {
  margin-left: 3%;
}

.center {
  margin: auto;
}

.float-right {
  float: right;
}

.pointer-cursor {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/home.component.scss"],"names":[],"mappings":"AAAA,iBAAA;AAEA;EACC,eAAA;AAAD;;AAGA;EACC,YAAA;AAAD;;AAGA;EACC,YAAA;AAAD;;AAGA;EACC,eAAA;AAAD","sourcesContent":["/* User Defined */\r\n\r\nh2 {\r\n\tmargin-left: 3%;\r\n}\r\n\r\n.center {\r\n\tmargin: auto;\r\n}\r\n\r\n.float-right {\r\n\tfloat: right;\r\n}\r\n\r\n.pointer-cursor {\r\n\tcursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
