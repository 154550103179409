// Copyright (C) 2022  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaderResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { FeedEntry } from "../model/feed-entry";
import { FeedUrl } from "../model/feed-url";
import { AuthService } from "./auth.service";
import { User } from "../model/user";

@Injectable({
	providedIn: "root",
})
export class DataService {
	private feedListUrl =
		location.protocol + "//" + location.host + "/api/feeds";
	private converterUrl =
		location.protocol + "//" + location.host + "/api/converter/?feedUrl=";
	private userUrl = location.protocol + "//" + location.host + "/api/user";

	constructor(private _http: HttpClient, private _authSvc: AuthService) {}

	public getFeeds(): Observable<FeedUrl[] | null | number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const url = this.feedListUrl + "/" + this._authSvc.currentUser.id;
		return this._http.get<FeedUrl[]>(url).pipe(
			catchError((err) => {
				console.log("getFeeds: " + JSON.stringify(err));
				return of(null);
			})
		);
	}

	public getFeedEntry(
		encodedUrl: string
	): Observable<FeedEntry | null | number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const fullUrl = this.converterUrl + encodedUrl;
		return this._http.get<FeedEntry>(fullUrl).pipe(
			catchError((err) => {
				console.log("getFeedEntry: " + JSON.stringify(err));
				return of(null);
			})
		);
	}

	public updateFeedUrl(feedUrlItem: FeedUrl): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const putUrl = this.feedListUrl + "/" + feedUrlItem.feedUrlId;
		feedUrlItem.title = feedUrlItem.title.trim();
		feedUrlItem.url = feedUrlItem.url.trim();
		return this._http
			.put<HttpHeaderResponse>(putUrl, feedUrlItem, {
				observe: "response",
			})
			.pipe(
				map((response) => {
					return response.status;
				})
			);
	}

	public addFeedUrl(feedUrlItem: FeedUrl): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const postUrl = this.feedListUrl + "/" + this._authSvc.currentUser.id;
		feedUrlItem.title = feedUrlItem.title.trim();
		feedUrlItem.url = feedUrlItem.url.trim();
		return this._http
			.post<HttpHeaderResponse>(postUrl, feedUrlItem, {
				observe: "response",
			})
			.pipe(
				map((response) => {
					return response.status;
				})
			);
	}

	public deleteFeedUrl(id: number): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const deleteUrl = this.feedListUrl + "/" + id;
		return this._http
			.delete<HttpHeaderResponse>(deleteUrl, { observe: "response" })
			.pipe(
				map((response) => {
					return response.status;
				})
			);
	}

	public addUser(userToAdd: User): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}

		return this._http.put<HttpHeaderResponse>(this.userUrl, userToAdd, {
			observe: "response"
		})
		.pipe(
			map((response) => {
				return response.status;
			})
		);
	}

	public deleteUser(id: number): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		const deleteUrl = this.userUrl + "/" + id;
		return this._http
			.delete<HttpHeaderResponse>(deleteUrl, { observe: "response" })
			.pipe(
				map((response) => {
					return response.status;
				})
			);
	}

	public getUsers(): Observable<User[] | null | number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		if (!this._authSvc.isAdmin) {
			return of(403);
		}
		return this._http.get<User[]>(this.userUrl).pipe(
			catchError((err) => {
				console.log("getUsers: " + JSON.stringify(err));
				return of(null);
			})
		);
	}

	public updateUser(user: User): Observable<number> {
		if (!this._authSvc.isLoggedIn) {
			return of(401);
		}
		if (!this._authSvc.isAdmin) {
			return of(403);
		}
		const putUrl = this.userUrl + "/" + user.id;
		user.salt = user.salt.trim();
		user.password = user.password.trim();
		user.username = user.username.trim();
		return this._http
			.put<HttpHeaderResponse>(putUrl, user, { observe: "response" })
			.pipe(
				map((response) => {
					return response.status;
				})
			);
	}
}
