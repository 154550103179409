// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { of, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../model/user";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	public redirectUrl!: string;
	public currentUser: User;
	private loginUrl = location.protocol + "//" + location.host + "/api/user";

	constructor(private _http: HttpClient, private _router: Router) {
		this.currentUser = JSON.parse(localStorage.getItem("currentUser")!);
	}

	public get isLoggedIn(): boolean {
		if (this.currentUser === null) return false;
		if (this.currentUser.id === 0) return false;
		if (isFinite(this.currentUser.id)) {
			return true;
		}
		return false;
	}

	public get isAdmin(): boolean {
		return !!this.currentUser.admin;
	}

	public login(username: string, password: string): Observable<User> {
		if (this.isLoggedIn) {
			return of(this.currentUser);
		}
		return this._http
			.post<User>(this.loginUrl, { username, password })
			.pipe(
				map((data) => {
					return data;
				})
			);
	}

	public signup(username: string, password: string): Observable<string> {
		return this._http
			.put<string>(this.loginUrl, { username, password })
			.pipe(
				map((data) => {
					return data;
				})
			);
	}

	public logout(): void {
		localStorage.removeItem("currentUser");
		localStorage.removeItem("userLibrary");
		localStorage.removeItem("userPlaylist");
		this.currentUser = { id: 0, username: "", password: "", emailAddress: "", salt: "", token: "", admin: 0 };
		this._router.navigate(["/login"]);
	}
}
