// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgForm } from "@angular/forms";
import { AgGridAngular } from "ag-grid-angular";
import { CellValueChangedEvent, ColDef } from "ag-grid-community";

import { FeedUrl } from "../model/feed-url";
import { DataService } from "../services/data.service";

@Component({
	selector: "app-feeds",
	templateUrl: "./feeds.component.html",
	styleUrls: ["./feeds.component.scss"],
})
export class FeedsComponent implements OnInit {
	@ViewChild("agGrid", { read: AgGridAngular, static: true })
	agGrid!: AgGridAngular;

	public title = "Manage Feeds";
	public rowData: FeedUrl[] = [];
	public showAddPodcast = false;

	public columnDefs: ColDef[] = [
		{
			headerName: "ID",
			field: "feedUrlId",
			checkboxSelection: true,
			width: 80,
		},
		{
			headerName: "Podcast Title",
			field: "title",
			editable: true,
			width: 200,
			minWidth: 200,
			maxWidth: 250,
		},
		{
			headerName: "Podcast URL",
			field: "url",
			editable: true,
			width: 600,
			minWidth: 350,
			maxWidth: 800,
		},
	];

	constructor(
		private _dataSvc: DataService,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getFeeds();
	}

	private getFeeds(): void {
		this._dataSvc.getFeeds().subscribe((data) => {
			if (data !== null && typeof data !== "number") {
				this.rowData = data;
			} else if (data === 401) {
				this.displayMessage(
					"There was an error retrieving the feeds. You don't appear to be logged in.",
					"Close"
				);
			} else {
				this.displayMessage(
					"An unknown error ocurred. The attempt to retrieve the feeds failed.",
					"Close"
				);
			}
		});
	}

	public addFeed(addUrlForm: NgForm): void {
		if (addUrlForm && addUrlForm.valid) {
			const feedUrl: FeedUrl = {
				feedUrlId: 0,
				title: addUrlForm.form.value.fTitle.trim(),
				url: addUrlForm.form.value.fUrl.trim(),
			};
			this._dataSvc.addFeedUrl(feedUrl).subscribe(
				(response) => {
					if (response === 204) {
						this.displayMessage("Successfully Added Podcast", "Close");
						this.getFeeds();
					}
				},
				(error) => {
					this.displayMessage(
						"The Add Feed operation failed. Are you attempting to add a duplicate feed?",
						"Close"
					);
				},
				() => {
					addUrlForm.resetForm();
					this.toggleAddPodcast();
				}
			);
		}
	}

	public deleteFeed(): void {
		const selectedNodes = this.agGrid.api.getSelectedNodes();
		if (selectedNodes.length === 0) {
			this.displayMessage("Please Select a Podcast to Delete", "Close");
			return;
		}
		const selectedData = selectedNodes.map((node) => node.data);
		this._dataSvc.deleteFeedUrl(selectedData[0].feedUrlId).subscribe(
			(response) => {
				if (response === 204) {
					this.displayMessage("Successfully Deleted Podcast", "Close");
					this.getFeeds();
				}
			},
			(error) => {
				this.displayMessage(
					"An error occurred. The operation failed.",
					"Close"
				);
			}
		);
	}

	private updateFeed($event: CellValueChangedEvent): void {
		const eq = $event.oldValue === $event.newValue;
		if (eq) {
			this.getFeeds();
			return;
		}
		this._dataSvc.updateFeedUrl($event.data).subscribe(
			(response) => {
				if (response === 204) {
					this.displayMessage("Successfully Updated Podcast", "Close");
				}
			},
			(error) => {
				this.displayMessage(
					"An error occurred. The update failed.",
					"Close"
				);
			}
		);
	}

	public onCellValueChanged($event: CellValueChangedEvent): void {
		this.updateFeed($event);
	}

	public toggleAddPodcast(): void {
		this.showAddPodcast = !this.showAddPodcast;
	}

	private displayMessage(message: string, action: string): void {
		this._snackBar.open(message, action, { duration: 5000 });
	}
}
