// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	Renderer2,
	HostListener
} from "@angular/core";
import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Subject, Subscription, Observable } from "rxjs";
import { buffer, debounceTime, map, filter } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

import { FeedEntry } from "./model/feed-entry";
import { FeedUrl } from "./model/feed-url";
import { DataService } from "./services/data.service";
import { DexiedbService } from "./services/dexiedb.service";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
	@ViewChild("audio", { read: ElementRef, static: false }) audioroot!: ElementRef;
	@ViewChild("itemTitle", { read: ElementRef, static: false }) itemTitle!: ElementRef;
	@ViewChild("itemInfo", { read: ElementRef, static: false }) itemInfo!: ElementRef;
	@ViewChild("itemAuthor", { read: ElementRef, static: false }) itemAuthor!: ElementRef;
	@ViewChild("playList", { read: ElementRef, static: false }) playlistEl!: ElementRef;
	@ViewChild("outerContainer", { read: ElementRef, static: false }) outerContainer!: ElementRef;

	public showSpinner = true;
	public playlist: Array<FeedEntry> = [];
	public feeds: Array<FeedEntry> = [];
	public audioSource = "";
	private playCounter = 0;
	private trackTime = 0;
	private skipDuration = 10;
	private showInfoDisplayTime = 20;
	private corsproxy = "https://corsanywhere-sb.azurewebsites.net/";

	// double-click for iOS.
	public click$ = new Subject<number>();
	public doubleClick$: Observable<number> = this.click$.pipe(
		buffer(this.click$.pipe(debounceTime(250))),
		map((list) => ({
			length: list.length,
			id: list[list.length - 1],
		})),
		filter((item) => item.length === 2),
		map((item) => item.id)
	);
	private clickSub!: Subscription;

	constructor(
		private _cDetRef: ChangeDetectorRef,
		private _rend: Renderer2,
		private _dataSvc: DataService,
		private _snackBar: MatSnackBar,
		private _db: DexiedbService
	) {}

	ngOnInit() {
		this.loadFeeds();
		// double-click for iOS.
		this.clickSub = this.doubleClick$.subscribe((id) => {
			this.playItemByIndex(id);
		});
	}

	@HostListener('window:beforeunload')
	ngOnDestroy() {
		this.clickSub.unsubscribe();
		// Save where listener is when the page reloads, closes, etc.
		if (this.playlist.length > 0) {
			const audioPlayer: HTMLAudioElement = this.audioroot.nativeElement;
			const trackTime = audioPlayer.currentTime.toString();
			this.playCounter = this.playCounter - 1;
			localStorage.setItem("playCounter", this.playCounter.toString());
			localStorage.setItem("trackTime", trackTime);
		}
	}

	private setCorsProxy(): void {
		if (location.host.includes("localhost")) {
			this.corsproxy = "http://localhost:8080/";
		}
	}

	private loadFeeds(): void {
		// Load the library list.
		// If the playlist is saved, it will load that instead (in convertFeed).
		var library: FeedUrl[] = [];
		this._dataSvc.getFeeds().subscribe((data) => {
			if (data !== null && typeof data !== "number") {
				library = data;
				this.convertFeed(library);
				//this.showSpinner = false;
				if (library.length === 0) {
					this.displayMessage(
						"You don't have any podcasts yet. Please go to the Library and add some.",
						""
					);
				}
			} else if (data === 401) {
				this.displayMessage(
					"There was an error retrieving the feeds. You don't appear to be logged in.",
					""
				);
			} else {
				this.displayMessage(
					"An unknown error ocurred. The attempt to retrieve the feeds failed.",
					""
				);
			}
		});
	}

	private convertFeed(library: FeedUrl[]): void {
		// Load the library and playlist if they were saved.
		// This is here because if it goes earlier, it tries to modify a ViewChild before it's rendered.
		var userPlaylist = localStorage.getItem("userPlaylist");
		if (userPlaylist !== null) {
			var userPlaylistFE: FeedEntry[] = JSON.parse(userPlaylist);
			this.playlist = userPlaylistFE;
			var userLibrary = localStorage.getItem("userLibrary");
			var userLibraryFE: FeedEntry[] = JSON.parse(userLibrary!);
			this.feeds = userLibraryFE;
			this.showSpinner = false;
			this.showControls();
			// Queue up the last item that was playing to its track time at the point the page reloaded, etc.
			var checkPlayCounter = localStorage.getItem("playCounter");
			var checkTrackTime = localStorage.getItem("trackTime");
			if (checkPlayCounter !== null && checkTrackTime !== null) {
				this.playCounter = parseInt(checkPlayCounter);
				this.trackTime = parseInt(checkTrackTime);
				this.playItemByIndex(this.playCounter);
			}

			return;
		}
		// Iterate over the library list and run each feed through the converter
		// to get JSON data back.
		for (let x = 0; x < library.length; x++) {
			const encodedUrl = this.b64Encode(library[x].url);
			this._dataSvc.getFeedEntry(encodedUrl).subscribe(
				(data) => {
					if (
						data === null ||
						(typeof data !== "number" && data.title === null)
					) {
						return;
					}
					if (data === 401) {
						this.displayMessage(
							"There was an error retrieving the feeds. You don't appear to be logged in.",
							""
						);
						return;
					}
					if (typeof data !== "number") {
						this.addItem(data);
						this.showControls();
						this.showSpinner = false;
					}
				},
				(error) => {
					console.log("ERROR: " + JSON.stringify(error));
				}
			);
		}
	}

	private addItem(item: FeedEntry): void {
		// Populate the Library draglist.
		if (item.enclosure === null) {
			return;
		}
		this.feeds.push(item);
		// pre-emptive de-dupe
		this.feeds = this.feeds.filter(
			(thing, pos, self) => self.indexOf(thing) === pos
		);
	}

	private showControls(): void {
		// Hide controls until the feed service has something.
		const container: HTMLDivElement = this.outerContainer.nativeElement;
		this._rend.removeClass(container, "pbp-display-none");
	}

	public drop(event: CdkDragDrop<FeedEntry[]>): void {
		// Handle CDK Drag/Drop event.
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		}
		var encTitle = this.getEnclosureTitle(event.container.data[event.currentIndex]["enclosure"]);
		// If the items was dropped in the playlist, store it locally.
		if (event.container.id === "cdk-drop-list-1"){
			this.storeLocalAudio(encTitle, event.container.data[event.currentIndex]["enclosure"]);
		// If the items was removed from the playlist, delete local copy.
		} else if (event.container.id === "cdk-drop-list-0") {
			this.deleteLocalAudio(encTitle);
		}
		// Save the state of the library and playlist.
		localStorage.setItem("userLibrary", JSON.stringify(this.feeds));
		localStorage.setItem("userPlaylist", JSON.stringify(this.playlist));
	}

	private async storeLocalAudio(encTitle: string, enclosure: string): Promise<void> {
		// Store the audio file locally in the IndexedDB.
		this.setCorsProxy();
		var dlUrl = this.corsproxy + enclosure;
		// var dlUrl = this.corsproxy + encodeURIComponent(enclosure);
		var dlRequest = new Request(dlUrl);
		dlRequest.headers.set("x-requested-with", "love");
		await fetch(dlRequest)
			.then( async (res) => {
				//const audioBlob = await res.blob();
				await this._db.audioFiles
					.put({
						filename: encTitle,
						filedata: await res.blob()
					})
					.then(data => {
						console.log(data);
					})
					.catch(error => {
						console.log(error);
					});
			});
	}

	private async deleteLocalAudio(encTitle: string): Promise<void> {
		await this._db.audioFiles
			.where("filename")
			.equals(encTitle)
			.delete();
	}

	public async playItem(): Promise<void> {
		// Handle play chores here.
		if (typeof this.playlist[this.playCounter] === "undefined") {
			return;
		}
		this.audioSource = this.playlist[this.playCounter].enclosure;
		// Get locally stored audio blob.
		var encTitle = this.getEnclosureTitle(this.audioSource);
		await this._db.audioFiles
			.where("filename")
			.equals(encTitle)
			.first()
				.then( (audioBlob) => {
					// Turn blob into something audio player can use.
					var objUrl = window.URL.createObjectURL(audioBlob?.filedata!);
					this._rend.setProperty(
						this.audioroot.nativeElement,
						"src",
						objUrl
					);
					this.showInfo(this.playlist[this.playCounter]);
					this.highlightCurrentTrack();
					this.audioroot.nativeElement.currentTime = this.trackTime;
					this.audioroot.nativeElement.play();
					this.playCounter++;
				});
	}

	private getEnclosureTitle(enc: string): string {
		var encTitle = enc.substring(enc.lastIndexOf("/") + 1, enc.length);
		encTitle = encTitle.includes("?") ? encTitle.substring(0, encTitle.indexOf("?")) : encTitle;
		return encTitle;
	}

	public async playItemByIndex(itemIndex: number): Promise<void> {
		// Play a specific item from the playlist.
		this.playCounter = itemIndex;
		// If this is not the item that was last playing before a page reload, etc.,
		// then reset the track time to 0.
		var checkItemIndex = localStorage.getItem("playCounter");
		if (checkItemIndex !== null) {
			if (itemIndex !== parseInt(checkItemIndex)) {
				this.trackTime = 0;
			}
		}
		await this.playItem();
	}

	public highlightCurrentTrack(): void {
		// Highlight the current-playing or first-queued track.
		const itemList: HTMLDivElement[] =
			this.playlistEl.nativeElement.querySelectorAll("div");
		const item: HTMLDivElement = itemList[this.playCounter];
		itemList.forEach((element) => {
			this._rend.removeClass(element, "pbp-box-selected");
		});
		this._rend.addClass(item, "pbp-box-selected");
	}

	public async clearPlaylist(): Promise<void> {
		if (this.isPlaying()) {
			// Replace window.confirm with material modal dialog.
			if (window.confirm("Do you want to stop the current podcast and clear the playlist?")) {
				this._rend.setProperty(
					this.audioroot.nativeElement,
					"src",
					""
				);
				localStorage.removeItem("userLibrary");
				localStorage.removeItem("userPlaylist");
				localStorage.removeItem("playCounter");
				localStorage.removeItem("trackTime");
				this.playlist = [];
				this.feeds = [];
				this.playCounter = 0;
				this.trackTime = 0;
				this.clearInfo();
				this.showSpinner = true;
				await this._db.audioFiles
					.where("id")
					.above(0)
					.delete()
						.then(data => console.log("Count of Files Deleted: " + data));
				this.loadFeeds();
			}
		}
	}

	private isPlaying(): boolean {
		const audioPlayer: HTMLAudioElement = this.audioroot.nativeElement;
		return !audioPlayer.paused && !audioPlayer.ended && 0 < audioPlayer.currentTime;
	}

	public fastForward(): void {
		const audioPlayer: HTMLAudioElement = this.audioroot.nativeElement;
		audioPlayer.currentTime = audioPlayer.currentTime + this.skipDuration;
	}

	public rewind(): void {
		const audioPlayer: HTMLAudioElement = this.audioroot.nativeElement;
		audioPlayer.currentTime = audioPlayer.currentTime - this.skipDuration;
	}

	private b64Encode(input: string, encoding = "utf-8"): string {
		const byteStr = btoa(input);
		return byteStr;
	}

	private displayMessage(message: string, action: string): void {
		this._snackBar.open(message, action, { duration: 45000 });
	}

	public async showInfoTemp(item: FeedEntry): Promise<void> {
		this.showInfo(item);
		if (typeof this.playlist[this.playCounter -1] === "undefined") {
			return;
		}
		// Display clicked item info, wait for timeout, then revert to highlighted item info.
		await new Promise((res) => setTimeout(res, this.showInfoDisplayTime * 1000));
		this.showInfo(this.playlist[this.playCounter - 1]);
	}

	private showInfo(item: FeedEntry): void {
		this._rend.setProperty(
			this.itemAuthor.nativeElement,
			"innerHTML",
			item.author
		);
		this._rend.setProperty(
			this.itemTitle.nativeElement,
			"innerHTML",
			item.title
		);
		this._rend.setProperty(
			this.itemInfo.nativeElement,
			"innerHTML",
			item.description
		);
	}

	public clearInfo(): void {
		this._rend.setProperty(
			this.itemAuthor.nativeElement,
			"innerHTML",
			""
		);
		this._rend.setProperty(
			this.itemTitle.nativeElement,
			"innerHTML",
			""
		);
		this._rend.setProperty(
			this.itemInfo.nativeElement,
			"innerHTML",
			""
		);
	}
}
