import { Injectable } from '@angular/core';
import Dexie, { Table } from "dexie";

@Injectable({
	providedIn: 'root'
})
export class DexiedbService extends Dexie {
	audioFiles!: Table<AudioFile, number>;

	constructor() {
		super("PodbotDB");

		this.version(5).stores({
			audioFiles: "++id, filename"
		});

		this.open()
			.then(
				(data) => {
					console.log("IndexedDB opened")
				})
			.catch(
				(error) => {
					console.log("ERROR: " + error.message)
				});
	}

}

export interface AudioFile {
	id?: number;
	filename: string;
	filedata: Blob;
}
