// Copyright (C) 2022  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthService } from "../services/auth.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
	public pageTitle = "Log In";
	public showSpinner = false;

	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _snackBar: MatSnackBar) {}

	public login(loginForm: NgForm): void {
		if (loginForm && loginForm.valid) {
			this.showSpinner = true;
			const userName: string = loginForm.form.value.userName.trim();
			const password: string = loginForm.form.value.password.trim();
			this._authService.login(userName, password).subscribe(
				response => {
					this.showSpinner = false;
					localStorage.setItem("currentUser", JSON.stringify(response));
					this._authService.currentUser = response;
					if (this._authService.redirectUrl) {
						this._router.navigateByUrl(this._authService.redirectUrl);
					} else {
						this._router.navigate(["/home"]);
					}
				},
				error => {
					this.showSpinner = false;
					console.log("ERROR: " + JSON.stringify(error));
					var errorText = JSON.parse(JSON.stringify(error));
					this.displayMessage(errorText.error.message, "");
				},
				() => {
					this.showSpinner = false;
				});
		} else {
			this.showSpinner = false;
			this.displayMessage("Please enter a user name and password.", "");
		}
	}

	private displayMessage(message: string, action: string): void {
		this._snackBar.open(message, action, { duration: 8000 });
	}

}
