import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { User } from "../model/user";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private _router: Router) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentUser: User = JSON.parse(localStorage.getItem("currentUser")!);
		if (currentUser !== null) {
			const cloned = req.clone({
				// Set JWT token.
				headers: req.headers.set("Authorization", "Bearer " + currentUser.token)
			});
			return next.handle(cloned).pipe(tap(
				success => {},
				err => {
					if (err.status === 401) {
						localStorage.removeItem("currentUser");
						this._router.navigate(["/login"]);
					}
				}
			));
		} else {
			return next.handle(req).pipe(tap(
				success => {
					this._router.navigate(["/login"]);
				},
				error => {
					console.log("ERROR (in interceptor): " + JSON.stringify(error));
					this._router.navigate(["/login"]);
				}
			));
		}
	}
}
