// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  color: orange;
  text-decoration: none;
  padding-left: 25px;
}

.pbp-link {
  color: orange;
  text-decoration: none;
  padding-left: 25px;
  font-size: small;
}

.pbp-link.active {
  text-decoration: underline;
}

.hide {
  display: none;
}

.show {
  display: block;
}

@media screen and (max-width: 700px) {
  .pbp-link {
    padding-left: 10px;
    font-size: x-small;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,qBAAA;EACA,kBAAA;AACD;;AAEA;EACC,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;AACD;;AAEA;EACC,0BAAA;AACD;;AAEA;EACC,aAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC;IACC,kBAAA;IACA,kBAAA;EACA;AACF","sourcesContent":["a {\r\n\tcolor: orange;\r\n\ttext-decoration: none;\r\n\tpadding-left: 25px;\r\n}\r\n\r\n.pbp-link {\r\n\tcolor: orange;\r\n\ttext-decoration: none;\r\n\tpadding-left: 25px;\r\n\tfont-size: small;\r\n}\r\n\r\n.pbp-link.active {\r\n\ttext-decoration: underline;\r\n}\r\n\r\n.hide {\r\n\tdisplay: none;\r\n}\r\n\r\n.show {\r\n\tdisplay: block;\r\n}\r\n\r\n@media screen and (max-width: 700px) {\r\n\t.pbp-link {\r\n\t\tpadding-left: 10px;\r\n\t\tfont-size: x-small;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
