import { Component } from "@angular/core";
import { FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthService } from "../services/auth.service";

@Component({
	selector: "app-usersignup",
	templateUrl: "./usersignup.component.html",
	styleUrls: ["./usersignup.component.scss"],
})
export class UsersignupComponent {
	public pageTitle = "Sign Up";
	passmatch1: string = "";
	passmatch2: string = "";

	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _snackBar: MatSnackBar) {}

	public signup(signupForm: NgForm): void {
		if (signupForm && signupForm.valid) {
			const username: string = signupForm.form.value.userName.trim();
			const password: string = signupForm.form.value.password.trim();
			this._authService.signup(username, password).subscribe(
				response => {
					this.displayMessage("User successfully created. You can log in now.", "");
				},
				error => {
					console.log("ERROR: " + JSON.stringify(error));
					var errorText = JSON.parse(JSON.stringify(error));
					this.displayMessage(errorText.error.message, "");
				},
				() => {
				}
			)
		} else {
			this.displayMessage("Please enter a user name and password.", "");
		}
	}

	private displayMessage(message: string, action: string): void {
		this._snackBar.open(message, action, { duration: 5000 });
	}

	public passwordMatch(): boolean {
		if (this.passmatch1 !== this.passmatch2) {
			return false;
		}
		return true;
	}
}
