// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { LoginComponent } from "./user/login.component";
import { HomeComponent } from "./home.component";
import { FeedsComponent } from "./feeds/feeds.component";
import { UseradminComponent } from "./useradmin/useradmin.component";
import { UsersignupComponent } from "./user/usersignup.component";
import { AuthGuard } from "./user/auth.guard";
import { SelectiveStrategy } from "./services/selective-strategy.service";

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(
			[
				{
					path: "home",
					component: HomeComponent,
					canActivate: [AuthGuard],
					data: { preload: true },
				},
				{
					path: "feeds",
					component: FeedsComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "useradmin",
					component: UseradminComponent,
					canActivate: [AuthGuard],
				},
				{ path: "usersignup", component: UsersignupComponent },
				{ path: "login", component: LoginComponent },
				{ path: "", redirectTo: "home", pathMatch: "full" },

				{ path: "**", redirectTo: "home" },
			],
			{ preloadingStrategy: SelectiveStrategy }
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
