// Copyright (C) 2019  shailar brown (shillbot (@) shillbot.com)
// GPL v2
import { Component } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

import { NavigationLink } from "./model/navigation-link";
import { AuthService } from "./services/auth.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	public admin = 0;
	public background: ThemePalette = "primary";
	public accent: ThemePalette = 'accent';
	public navigationLinks: NavigationLink[] = [
		{
			label: "User Admin",
			link: "/useradmin",
			index: 0,
			adminLink: true,
		},
		{
			label: "Player",
			link: "/home",
			index: 1,
			adminLink: false
		},
		{
			label: "Library",
			link: "/feeds",
			index: 2,
			adminLink: false
		}
	];

	constructor(private _auth: AuthService) {
		if (this._auth.isLoggedIn) {
			this.admin = this._auth.currentUser.admin;
		}
	}

	public logout(): void {
		// Log user out and remove their login from LocalStorage.
		this._auth.logout();
	}

	public showAdminLink(index: number): boolean {
		if (!this._auth.isLoggedIn) {
			return false;
		} else if (index === 10) { // index 10 means logout link
			return true;
		} else if (this._auth.isAdmin) {
			return true;
		} else if (!this.navigationLinks[index].adminLink) {
			return true;
		}
		return false;
	}
}
